import React from "react";
import { Fragment } from "react";
import styled from "styled-components";
import Typewriter from "typewriter-effect";
import Button from "./Button";
import SecurityLogo01 from "../assets/fintech-egypt-logo.png";
import SecurityLogo02 from "../assets/Microsoft_logo_(2012).svg.png";
import SecurityLogo03 from "../assets/code_logo.svg";
import SecurityLogo04 from "../assets/riyadbank.svg";

import { FormattedMessage } from "react-intl";
import { Context } from "./Wrapper";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

const Title = styled.h1`
  font-size: 320%;
  /* text-transform: capitalize; */
  width: 100%;
  color: ${(props) => props.theme.text};
  /* align-self: center; */
  font-weight: 500;
  /* color: #002856; */
  /* margin-top: 70px; */
  /* text-align: center; */

  display: flex;
  span {
    /* text-transform: uppercase; */
    /* font-family: "Akaya Telivigala", cursive; */
  }

  .text-1 {
    color: ${(props) => props.theme.text};
  }
  .text-2 {
    color: ${(props) => props.theme.brandBlue};
    /* color: white; */

  }
  .text-3 {
    color: #CCA010;
    /* color: #009ad7; */
  }
  .text-4 {
    color: #00B1A5;
    /* color: #009ad7; */
  }


  @media (max-width: ${(props) => props.theme.width}) {
    font-size: 260%;
    align-self: center;
    text-align: center;
    /* width: 90%; */
    /* margin-top: 10px; */
    flex-direction: column;
  }

`;

const SubTitle = styled.h3`
  /* font-size: x-large; */
  /* text-transform: capitalize; */
  color: ${(props) => props.theme.text};
  /* text-align: center; */

  /* font-weight: 600; */
  /* margin-top: 2rem; */
  margin-bottom: 1rem;

  width: 100%;
  /* align-self: center; */

  @media (max-width: ${(props) => props.theme.width}) {
    align-self: center;
    text-align: center;
    font-size: large;
    width: 80%;


  }
`;

const SubTextLight = styled.p`
  font-size: 220%;
  color: rgba(${(props) => props.theme.textRgba}, 0.6);
  margin-top: 1rem;
  /* width: 80%; */
  font-weight: 400;
  align-self: center;
  padding-top: 50px;

  @media (max-width: 75em) {
    width: 100%;
    font-size: medium;
  }

  @media (max-width: 48em) {
    font-size: medium;
    width: 100%;
    text-align: center;
  }

  @media (max-width: 30em) {
    font-size: medium;
  }
`;

const ButtonContainer = styled.div`
align-self: start;
  /* margin-top: 50px; */
  /* align-content: center; */

  @media (max-width: 75em) {
    align-self: center;

    button {
      margin: 0 auto;
    }
  }
`;

/// Media
const Container = styled.div`
  width: 100%;

  /* margin: 2rem auto; */

  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  

  @media (max-width: 75em) {
    width: 80%;
  }
  @media (max-width: 64em) {
    width: 85%;
  }
  @media (max-width: 48em) {
    width: 90%;
    justify-content: center;
  }
`;

const Item = styled.div`
  width: calc(12rem - 4vw);
  padding: 1rem 0;
  /* color: ${(props) => props.theme.body}; */
  /* margin: 2rem 1rem; */
  position: relative;

  /* border: 2px solid ${(props) => props.theme.text}; */
  /* border-radius: 20px; */
  /* transition: all 0.3s ease; */
  justify-content: center;

  img {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
  }

  &:hover {
    img {
      filter: grayscale(0%);
      -webkit-filter: grayscale(0%);
      -moz-filter: grayscale(0%);

      transform: translateY(-2rem) scale(1.2);
    }
  }

  @media (max-width: 75em) {
    width: calc(11rem - 4vw);
  }

  @media (max-width: 64em) {
    width: calc(10rem - 4vw);
  }

  @media (max-width: 48em) {
    width: calc(8rem - 4vw);
  }
`;

const ImageContainer = styled.div`
  /* width: 80%; */
  margin: 0 auto;
  /* background-color: ${(props) => props.theme.carouselColor}; */
  /* border: 1px solid ${(props) => props.theme.text}; */
  padding: 1rem;

  border-radius: 20px;
  cursor: pointer;

  img {
    width: 100%;
    /* height: auto; */
    transition: all 0.3s ease;
  }
`;



const WordCarousel = styled.div`

  /* font-size: 36px; */
  /* font-weight: 100; */
  div {
      overflow: hidden;
      position: relative;
      float: right;
      height: 65px;
      padding-top: 10px;
      margin-top: -10px;
      li {
          /* font-weight: 700; */
          /* padding: 0 10px;  */
          height: 45px;
          margin-bottom: 45px;

          display: block;
      }

      @media (max-width: 64em) {
align-self: center;
float: none;
padding-top: 20px;
  }

  }


.flip2 { animation: flip2 6s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }
.flip3 {padding: 0; animation: flip3 8s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }
.flip4 { animation: flip4 10s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }
.flip5 { animation: flip5 12s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }

@keyframes flip2 {
  0% { margin-top: -180px; }
  5% { margin-top: -90px;  }
  50% { margin-top: -90px; }
  55% { margin-top: 0px; }
  99.99% { margin-top: 0px; }
  100% { margin-top: -180px; }
}

@keyframes flip3 {
  0% { margin-top: -270px; }
  5% { margin-top: -180px; }
  33% { margin-top: -180px; }
  38% { margin-top: -90px; }
  66% { margin-top: -90px; }
  71% { margin-top: 0px; }
  99.99% { margin-top: 0px; }
  100% { margin-top: -270px; }
}

@keyframes flip4 {
  0% { margin-top: -360px; }
  5% { margin-top: -270px; }
  25% { margin-top: -270px; }
  30% { margin-top: -180px; }
  50% { margin-top: -180px; }
  55% { margin-top: -90px; }
  75% { margin-top: -90px; }
  80% { margin-top: 0px; }
  99.99% { margin-top: 0px; }
  100% { margin-top: -360px; }
}

@keyframes flip5 {
  0% { margin-top: -450px; }
  5% { margin-top: -360px; }
  20% { margin-top: -360px; }
  25% { margin-top: -270px; }
  40% { margin-top: -270px; }
  45% { margin-top: -180px; }
  60% { margin-top: -180px; }
  65% { margin-top: -90px; }
  80% { margin-top: -90px; }
  85% { margin-top: 0px; }
  99.99% { margin-top: 0px; }
  100% { margin-top: -450px; }
}

`;




const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const MemberComponent = ({ img, name = " ", position = " ", url }) => {
  return (
    <Item>
      <ImageContainer>
        <img
          src={img}
          alt={name}
          onClick={() => {
            openInNewTab(url);
          }}
        />
      </ImageContainer>
    </Item>
  );
};

const TypeWriterText = (props) => {
  const context = React.useContext(Context);

  const intl = useIntl();
  const bank = intl.formatMessage({ id: "app.home.bank" });

  return (
    <Fragment>

      <div style={{ margin: 0, padding: 0, width: "100%" }}>
        <Title>
          <WordCarousel>

            {/* <FormattedMessage id="app.home.any" defaultMessage="Any" /> */}
            <span>Re-imagine&nbsp;</span>
            {/* <br /> */}
            {/*
        <Typewriter
          options={{
            autoStart: true,
            loop: true,
          }}
          onInit={(typewriter) => {
            typewriter
              // .typeString("<span class='text-1'>Financial Institution</span>")
              // .pauseFor(2000)
              // .deleteAll()
              .typeString(str)
              .pauseFor(2000)
              .deleteAll()
              //.deleteChars(4)
              .typeString("<span class='text-3'>Insurance</span>")
              .pauseFor(2000)
              .deleteAll()
              //.deleteChars(10)

              .typeString("<span class='text-4'>Microfinance</span>")
              .pauseFor(2000)
              .deleteAll()
              //.deleteChars(12)
              .start();
          }}
        /> */}
            {/*
        <span className="text-2">
          <FormattedMessage id="app.home.bank" defaultMessage="Bnak" />
        </span>
        */}
            {/*
        <span className="text-2">
          <FormattedMessage
            id="app.home.insurance"
            defaultMessage="Insurance"
          />
        </span>
        */}
            {/* <span className="text-2">
          Banking
        </span> */}



            <div>
              <ul class="flip3">
                <li class="text-2">Banking</li>
                <li class="text-3">Government</li>
                <li class="text-4">Insurance</li>
              </ul>
            </div>



            {/* <br /> */}
            {/* <FormattedMessage id="app.home.anywhere" defaultMessage="Anywhere" /> */}

          </WordCarousel>

        </Title>
        <Title>      <span>Experiance </span>
        </Title>
      </div>



      <SubTitle>
        {/* <FormattedMessage
          id="app.home.areYouBoared"
          defaultMessage="Are You Bored Of The 90s' Financial Services? Try Something New."
        /> */}
        We transform how financail products and services are delivered to customers.

      </SubTitle>

      <ButtonContainer>
        <Link to={"/contact-us"} onClick={() => { }}><Button text="Contact Us"></Button></Link>
      </ButtonContainer>


      {/* <SubTextLight>
        <FormattedMessage
          id="app.home.recognizedBy"
          defaultMessage="Recognized By:"
        />
      </SubTextLight>
      <Container>
        <MemberComponent
          img={SecurityLogo01}
          url="https://fintech-egypt.com/news/news_details.php?id=151"
          alt="Fintech-Egypt"
        />
        .
        <MemberComponent
          img={SecurityLogo03}
          url="https://www.linkedin.com/feed/update/urn:li:activity:6940327127892254720"
          alt="Microsoft"
        />
        .
        <MemberComponent
          img={SecurityLogo02}
          url=""
          alt="CODE"
        />
                .
        <MemberComponent
          img={SecurityLogo04}
          url=""
          alt="CODE"
        />
      </Container> */}
    </Fragment>
  );
};

export default TypeWriterText;

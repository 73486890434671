import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { FormattedMessage } from "react-intl";
import { Context } from "../Wrapper";
import { useIntl } from "react-intl";
import { event } from "jquery";
import { Link } from "react-router-dom";

const Section = styled.section`
  /* min-height: 100vh; */
  width: 100vw;
  background-color: ${(props) => props.theme.body};
  position: relative;
  direction: ${(props) => props.direction};

  display: flex;
  flex-direction: column;
  align-items: center;

  /* font-family: ${(props) =>
    props.direction === "rtl" ? "HelveticaNeueLT Arabic 55 Roman" : "Poppins"}; */
`;

const Container = styled.div`
  width: 70%; //section content wdith
  /* height: 130vh; */
  background-color: ${(props) => props.theme.body};
  margin: 4em auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 90em) {
    width: 75%;
    /* height: 140vh; */
  }

  @media (max-width: 75em) {
    width: 80%;
    /* height: 150vh; */
  }

  @media (max-width: 64em) {
    width: 85%;
    /* height: 160vh; */
  }

  @media (max-width: 53em) {
    width: 90%;
    /* height: 170vh; */
  }

  @media (max-width: 48em) {
    width: 90%;
    /* height: 130vh; */
  }

  @media (max-width: 30em) {
    width: 90%;
    /* height: 220vh; */
  }
`;

const Title = styled.h1`
  /* font-size: ${(props) => props.theme.fontxxl}; */
  /* text-transform: capitalize; */

  color: ${(props) => props.theme.text};
  /* align-self: flex-start; */
  display: flex;
  /* justify-content: center;
  align-items: center; */
  margin: 1rem auto;
  /* border-bottom: 2px solid ${(props) => props.theme.text}; */
  width: fit-content;
  /* color: #002856; */

  width: ${(props) => props.theme.width};
  padding-top: 60px;
  padding-bottom: 40px;
  font-size: xx-large;


  @media (max-width: ${(props) => props.theme.width}) {
    font-size: x-large;
    width: 90%;
    text-align: center;
  }
`;
const SubText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.text};
  margin: 1rem auto;
  width: 80%;
  font-weight: 400;
  align-items: center;
  /* align-self: center; */
  align-self: flex-start;
  text-align: center;

  @media (max-width: 64em) {
    width: 80%;
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontmd};
  }
`;

const Items = styled.ul`
  list-style: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: lightblue; */
  flex-basis: fit-content;

  @media (max-width: 48em) {
    width: 90%;
  }

  & > *:nth-of-type(2n + 1) {
    justify-content: start;
    @media (max-width: 48em) {
      justify-content: center;
    }

    
    div {
      border-radius: 50px 0 50px 0;
      text-align: right;

      @media (max-width: 48em) {
        border-radius: 0 50px 0 50px;
        text-align: left;
        p {
          border-radius: 0 40px 0 40px;
        }
      }
    }
    p {
      border-radius: 40px 0 40px 0;
    }
  }
  & > *:nth-of-type(2n) {
    justify-content: end;

    @media (max-width: 48em) {
      justify-content: center;
    }

    div {
      border-radius: 0 50px 0 50px;
      text-align: left;
    }
    p {
      border-radius: 0 40px 0 40px;
    }
  }
`;

const Item = styled.li`
  width: 100%;
  height: 100%;
  display: flex;

  @media (max-width: 48em) {
    justify-content: center !important ;
  }
`;

const ItemContainer = styled.div`
  width: 40%;
  height: fit-content;
  padding: 1rem;
  border: 3px solid ${(props) => props.theme.text};

  @media (max-width: 48em) {
    width: 70%;
  }
`;

const Box = styled.p`
  height: fit-content;
  /* background-color: ${(props) => props.theme.carouselColor}; */
  background-color: #f5f5f5;
  /* background-color: #3fbdf1; */
  /* background-color: #91dcf8; */

  color: ${(props) => props.theme.text};
  padding: 1rem;
  position: relative;
  border: 1px solid ${(props) => props.theme.text};
`;

const SubTitle = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontlg};
  /* text-transform: capitalize; */
  color: ${(props) => props.theme.text};

  font-weight: 600;
  /* margin: 0.5rem 0; */
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontlg};
    font-weight: 600;
  }
`;
const Text = styled.p`
  display: block;
  font-size: ${(props) => props.theme.fontmd};
  /* text-transform: capitalize; */
  color: ${(props) => props.theme.text};

  font-weight: 400;
  margin: 0.5rem 0;
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }
`;

const ItemsX = styled.ul`
  list-style: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: lightblue; */
  flex-basis: fit-content;

  /* @media (max-width: 48em) {
    width: 90%;
  } */

  /* & > *:nth-of-type(2n + 1) { */
  justify-content: start;
  @media (max-width: 48em) {
    justify-content: center;
  }

  div {
    border-radius: 50px 0 50px 0;
    text-align: right;

    @media (max-width: 48em) {
      border-radius: 0 50px 0 50px;
      text-align: left;
      p {
        border-radius: 0 40px 0 40px;
      }
    }
  }
  p {
    border-radius: 40px 0 40px 0;
  }
  /* }
   & > *:nth-of-type(2n) {
    justify-content: end;

    @media (max-width: 48em) {
      justify-content: center;
    }

    div {
      border-radius: 0 50px 0 50px;
      text-align: left;
    }
    p {
      border-radius: 0 40px 0 40px;
    }
  } */
`;

const ItemX = styled.li`
  width: 100%;
  height: 100%;
  display: flex;
  margin: 1rem 1rem;

  @media (max-width: 48em) {
    justify-content: center !important ;
  }
`;

const ItemContainerX = styled.div`
  width: 40%;
  height: fit-content;
  padding: 1rem;
  border: 3px solid ${(props) => props.theme.text};

  @media (max-width: ${(props) => props.theme.width}) {
    width: 90%;
  }
`;



//$$$$$$$$$$$$$$$  New

/* Style the tab */
const Tab = styled.div`
  direction: ${(props) => props.direction};

margin-bottom: 20px;
display: flex;
gap: 16px;
  flex-wrap: nowrap;
  /* justify-content: center;
  align-content: center;  */

  /* margin: 20px; */
  /* overflow: hidden; */
  justify-content: flex-start;
  width: ${(props) => props.theme.width};
  /* width: auto; */
  /* border: 1px solid #ccc; */
  /* background-color: #f1f1f1; */

  overflow-x: auto;
  /* scroll-snap-type: x mandatory; */
  list-style-type: none;

      /* Hide scrollbar in Firefox */
      scrollbar-width: none;

/* Hide scrollbar in IE and Edge */
-ms-overflow-style: none;

  /* flex: 1; */
  /* width: auto; */

::-webkit-scrollbar {
  display: none;
}

  @media (max-width: ${(props) => props.theme.width}) {
    /* width: 90%; */
    /* width: 150px; */
    width: 90%;
    align-content: flex-start; 

    /* width: fit-content; */
    overflow-x: auto;
    padding-left: 0px;
    justify-content: normal;

    font-size: medium;

  }


`;


/* Style the buttons inside the tab */
const TabButton = styled.button.attrs({ className: 'tablink', })`
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 12px;
  transition: 0.1s;
  font-size: x-large;
  text-align: left;
  :hover{
    /* background-color: #ddd; */
    color:   ${(props) => props.theme.brandBlue};;
    font-weight: bold;
  }

  &.active {
    /* background-color: #ccc; */
    color: ${(props) => props.theme.brandBlue};;
    border-bottom: 3px solid ${(props) => props.theme.brandBlue};;

    padding-bottom: 8px;
  }
`;



/* Style the tab content */
const TabContent = styled.div.attrs({ className: 'tabcontent', })`
  display: none;
  padding: 6px 12px;
  /* border: 1px solid #ccc; */


  /* display: flex; */
  /* justify-content: space-between; */
  justify-content: space-between;
  /* align-content: center; */
  flex-wrap: nowrap; 
  /* overflow: auto; */

  margin-bottom: 100px;

  /* display: flex; */
    flex-flow: row;
    justify-content: space-around;



    flex-wrap: nowrap;
  justify-content: center;
  align-content: center; 

  /* margin: 20px; */
  /* overflow: hidden; */
  /* justify-content: flex-start; */
  /* width: ${(props) => props.theme.width}; */
  width: ${(props) => props.theme.width};
  /* border: 1px solid #ccc; */
  /* background-color: #f1f1f1; */

  overflow-x: auto;
  /* scroll-snap-type: x mandatory; */
  list-style-type: none;

      /* Hide scrollbar in Firefox */
      scrollbar-width: none;

/* Hide scrollbar in IE and Edge */
-ms-overflow-style: none;

  /* flex: 1; */

::-webkit-scrollbar {
  display: none;
}

  @media (max-width: ${(props) => props.theme.width}) {
    /* width: 90%; */
    /* width: 150px; */
    width: 90%;
    align-content: flex-start; 

    /* width: fit-content; */
    overflow-x: auto;
    padding-left: 0px;
    justify-content: normal;

  }


`;


const TabContentList = styled.ul.attrs({ className: 'tabcontent', })`
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  list-style: none;

    /* Hide scrollbar in Firefox */
    scrollbar-width: none;

  /* Hide scrollbar in IE and Edge */
  -ms-overflow-style: none;

  padding-left: 0px;

    flex: 1;
  ::-webkit-scrollbar {
  display: none;
  }
`;

const Item2 = styled.li`
  /* width: calc(20rem - 4vw); */
  /* width: 20px; */
  padding: 24px 24px;
  color: ${(props) => props.theme.body};
  /* margin: 1rem 2rem; */
  position: relative;

  /* border: 2px solid ${(props) => props.theme.text}; */
  
  transition: all 0.3s ease;
  flex-grow: 1;

  /* background-color: #EFF2FB; */
  background-color: ${(props) => props.theme.lightGray};

  margin: 16px;
  border: 1px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;

  flex: 0 0 25%; /* don't grow, don't shrink, stay fixed at 50% width */
  scroll-snap-align: center;
  flex-shrink: 0;

  &:hover {
    img {
      transform: translateY(-2rem) scale(1.2);
    }
  }

  @media (max-width: ${(props) => props.theme.width}) {
    width: 90%;
    flex: 0 0 60%;// don't grow, don't shrink, stay fixed at 50% width

  }
`;
const Name = styled.h3`
  /* font-size: ${(props) => props.theme.fontsm}; */
  display: flex;
  /* align-items: center; */
  /* justify-content: left; */
  /* text-transform: uppercase; */
  color: ${(props) => props.theme.text};
  margin-top: 1rem;
  /* text-align: -webkit-center; */
  min-height: 40px;
`;

const Position = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-transform: capitalize; */
  color: rgba(${(props) => props.theme.textRgba}, 0.9);
  font-weight: 400;
  padding-top: 1rem;
  line-height: normal;
  margin-bottom: auto;
  line-height: 1.6;

`;

const Icon = styled.i`
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 20px;  /* Preferred icon size */
  /* display: inline-block; */
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ${(props) => props.direction};
  vertical-align: top;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  /* font-feature-settings: 'liga'; */
  margin-right: 10px;
  margin-left: 10px;



`;



const ListWrapper = styled.div`
  position: relative;
  display: flex;
    flex-flow: row;
    align-items: center;
`;

const ListWrapperButton = styled.button`
  position: absolute;
  top: 50%;

  width: 3rem;
  height: 3rem;

  transform: translateY(-50%);

  @media (max-width: ${(props) => props.theme.width}) {

    display: none;

  }

`;

const ListWrapperButtonPrevious = styled.button`
  width: 3rem;
  height: 3rem;
  left: 1.5rem;

  background-color: white;
  border: 0px;
  transform: rotate(180deg);

  /* position: fixed; */
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 50%;
  background-color: #EFF2FB;
  &:hover {

    /* transform: scale(1.2); */
    font-size: larger;

    color: ${(props) => props.theme.brandBlue};;

  }

  &:active {
    /* transform: scale(0.9); */

  }
  @media (max-width: ${(props) => props.theme.width}) {

display: none;

}
`;

const ListWrapperButtonNext = styled.button`

width: 3rem;
  height: 3rem;
  right: 1.5rem;
  background-color: white;
  border: 0px;

  /* position: fixed; */
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 50%;
  background-color: #EFF2FB;
  &:hover {
    /* transform: scale(1.2); */
    color: ${(props) => props.theme.brandBlue};;
    font-size: larger;

  }

  &:active {
    /* transform: scale(0.9); */
  }

  @media (max-width: ${(props) => props.theme.width}) {

display: none;

}
`;


const MemberComponent = ({ id, img, name = " ", position = " ", route = "" }) => {

  const context = React.useContext(Context);

  return (
    <Item2 id={id}>
      {/* <ImageContainer>
        <img src={img} alt={name} />
      </ImageContainer> */}
      <Name>{name}</Name>
      <Position>{position}</Position>
      <br></br>
      {/* <Link to={route} onClick={() => {  }} > */}
      <Link to={route} >

        <a href="#" style={{ color: "#009ad7", display: "flex", justifyContent: "flex-end" }}>Read more {context.locale === 'ar' ? <Icon style={{ "-webkit-transform": "scaleX(-1)", transform: "scaleX(-1)" }}>chevron_right</Icon> : <Icon>chevron_right</Icon>}</a>
      </Link>
    </Item2>
  );
};


class TabButtonX2 extends React.Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();
    this.props.addToRef(this.myRef);

    //init value


    //   this.myRef.current.className.replace(" active", "");
  }


  componentDidMount() {
    if (this.props.id === "1") {
      //console.log("----")
      this.myRef.current.className = this.myRef.current.className + " active";
    }
  }
  render() {
    return (
      <TabButton id={this.props.id} ref={this.myRef} onClick={this.props.onClick}>
        {this.props.title}
      </TabButton>
    );
  }


}


class TabContentX2 extends React.Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();
    //console.log(">");
    //console.log(this.myRef);
    this.props.addToRef(this.myRef);
  }
  componentDidMount() {
    if (this.props.id === "1") {
      //console.log("----")
      this.myRef.current.style.display = "block";
    }
  }
  render() {
    return (
      <TabContent id={this.props.id} ref={this.myRef}>
        <h3>{this.props.title}</h3>
        <p>{this.props.subtext}</p>
        {this.props.children}
      </TabContent>
    );
  }


}





const RoadMapItemX = ({ title, subtext, addToRef }) => {
  return (
    <ItemX>
      <ItemContainerX>
        <Box>
          <SubTitle> {title} </SubTitle>
          <Text> {subtext}</Text>
        </Box>
      </ItemContainerX>
    </ItemX>
  );
};





const RoadMapItem = ({ title, subtext, addToRef }) => {
  return (
    <Item>
      <ItemContainer>
        <Box>
          <SubTitle> {title} </SubTitle>
          <Text> {subtext}</Text>
        </Box>
      </ItemContainer>
    </Item>
  );
};

const Usecases = () => {
  const [ref, setRef] = useState([]);

  const revealRefs = useRef([]);
  revealRefs.current = [];
  gsap.registerPlugin(ScrollTrigger);



  const addToREfs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }

  };



  const handleClick = (direction, refList, refItem) => {

    const list = document.getElementById(refList);
    //console.log(list)

    // We want to know the width of one of the items. We'll use this to decide how many pixels we want our carousel to scroll.
    const item = document.getElementById(refItem);
    // console.log(item)

    const itemWidth = item.offsetWidth;

    // Based on the direction we call `scrollBy` with the item width we got earlier
    if (direction === "previous") {
      list.scrollBy({ left: -itemWidth, behavior: "smooth" });
    } else {
      list.scrollBy({ left: itemWidth, behavior: "smooth" });
    }
  }

  const openCity = (event, cityName) => {
    // var i, tabcontent, tablinks;
    // tabcontent = document.getElementsByClassName("tabcontent");
    // console.log("here")
    // console.log(tabcontent[i]);
    // console.log(document.getElementById("London"))

    // let t1 = gsap.timeline();
    //console.log("hhhhhhhh");

    //console.log(revealRefs);

    revealRefs.current.forEach((el, index) => {

      //console.log("Event");

      //console.log(el);


      if (el.current?.className.includes("tabcontent")) {
        // console.log("found tabcontent");
        el.current.style.display = "none";
        if (el.current.id === cityName) {
          // console.log("found");
          el.current.style.display = "block";
        }
      }
      else
        if (el.current?.className.includes("tablink")) {
          el.current.className = el.current.className.replace(" active", "");

        }

      // t1.fromTo(

      //   // el.childNodes[0],
      //   // {
      //   //   y: "0",
      //   // },
      //   // {
      //   //   y: "-30%",
      //   //   scrollTrigger: {
      //   //     id: `section-${index + 1}`,
      //   //     trigger: el,
      //   //     start: `top center+=200px`,
      //   //     end: `bottom center`,
      //   //     scrub: true,
      //   //     // markers: true,
      //   //   },
      //   // }
      // );
    });
    //document.getElementById(cityName).style.display = "block";
    //console.log(event);
    event.currentTarget.className += " active";


    // for (i = 0; i < tabcontent.length; i++) {
    //   tabcontent[i].style.display = "none";
    // }
    // tablinks = document.getElementsByClassName("tablinks");
    // for (i = 0; i < tablinks.length; i++) {
    //   tablinks[i].className = tablinks[i].className.replace(" active", "");
    // }
    // document.getElementById(cityName).style.display = "block";
    // event.currentTarget.className += " active";
  }

  useEffect(() => {
    // console.log(revealRefs.current);
  }, [revealRefs]);

  useLayoutEffect(() => {

    //console.log(revealRefs);
    let t1 = gsap.timeline();

    // revealRefs.current.forEach((el, index) => {
    //   //console.log(t1);

    //   t1.fromTo(
    //     el.childNodes[0],
    //     {
    //       y: "0",
    //     },
    //     {
    //       y: "-30%",
    //       scrollTrigger: {
    //         id: `section-${index + 1}`,
    //         trigger: el,
    //         start: `top center+=200px`,
    //         end: `bottom center`,
    //         scrub: true,
    //         // markers: true,
    //       },
    //     }
    //   );
    // });

    return () => { };
  }, []);

  const context = React.useContext(Context);

  const intl = useIntl();
  const busAccounting = intl.formatMessage({
    id: "app.usecases.busAccounting",
  });
  const busAccountingDetails = intl.formatMessage({
    id: "app.usecases.busAccountingDetails",
  });
  const pfm = intl.formatMessage({ id: "app.usecases.pfm" });
  const pfmDetails = intl.formatMessage({ id: "app.usecases.pfmDetails" });
  const accVer = intl.formatMessage({ id: "app.usecases.accVer" });
  const accVerDetails = intl.formatMessage({
    id: "app.usecases.accVerDetails",
  });
  const custId = intl.formatMessage({ id: "app.usecases.custId" });
  const custIdDetails = intl.formatMessage({
    id: "app.usecases.custIdDetails",
  });
  const prodComp = intl.formatMessage({ id: "app.usecases.prodComp" });
  const prodCompDetails = intl.formatMessage({
    id: "app.usecases.prodCompDetails",
  });
  const credAss = intl.formatMessage({ id: "app.usecases.credAss" });
  const credAssDetails = intl.formatMessage({
    id: "app.usecases.credAssDetails",
  });

  return (
    <Section
      id="roadmap"
      direction={context.locale.startsWith("ar") ? "rtl" : "ltr"}
    >
      <Title>
        {/* <FormattedMessage id="app.usecases.title" defaultMessage="Use Cases" /> */}
        Our Solutions
      </Title>
      <Tab direction={context.locale.startsWith("ar") ? "rtl" : "ltr"}
      >
        {/* <TabButton onclick={openCity('London')}>London</TabButton>
        <TabButton onclick={openCity( 'Paris')}>Paris</TabButton>
        <TabButton onclick={openCity('Tokyo')}>Tokyo</TabButton> */}

        <TabButtonX2 id="1" addToRef={addToREfs} onClick={(event) => openCity(event, '1')} title="Data Exchange" />
        <TabButtonX2 id="2" addToRef={addToREfs} onClick={(event) => openCity(event, '2')} title="Open Banking" />
        <TabButtonX2 id="3" addToRef={addToREfs} onClick={(event) => openCity(event, '3')} title="Financial System" />


      </Tab>


      <TabContentX2
        id="1"
        addToRef={addToREfs}
      // title="Paris"
      // subtext="Paris is the capital of France."
      >
        <ListWrapper>
          {/* <div style={{ textAlign: "-webkit-center" }}>
            <ListWrapperButtonPrevious onClick={(event) => handleClick("previous", "list1245", "item1234")}><Icon>chevron_right</Icon></ListWrapperButtonPrevious>
          </div> */}
          <TabContentList>
            <MemberComponent route="/service1" name="Data Exchange Gateway" position="Enables organizations to securely share data and services with their authorized partners, facilitating seamless system-to-system integration." />
            <MemberComponent route="/service2" name="Data Exchange Client Connector" position="Enables organizations to securely access data and services shared through their partners' B2B, B2G, or G2G gateways." />
            <MemberComponent route="/service3" name="Data Exchange Framework" position="Data exchange framework and a standardized approach to data exchange among multiple parties and roles: Data holders, data owners, data consumers, and regulators." />
          </TabContentList>
          {/* <div style={{ textAlign: "-webkit-center" }}>
            <ListWrapperButtonNext onClick={(event) => handleClick("next", "list1245", "item1234")}><Icon>chevron_right</Icon></ListWrapperButtonNext>
          </div> */}
        </ListWrapper>

      </TabContentX2>


      <TabContentX2
        id="2"
        addToRef={addToREfs}
      // title="London"
      // subtext="London is the capital city of England."
      >
        <ListWrapper>
          <div style={{ textAlign: "-webkit-center" }}>
            <ListWrapperButtonPrevious onClick={(event) => handleClick("previous", "list1245", "item1234")}><Icon>chevron_right</Icon></ListWrapperButtonPrevious>
          </div>
          <TabContentList id="list1245">
            <MemberComponent route="/service4" id="item1234" name="TPP Enabelment" position="Empowering financial and non-financial organizations to become Third-Party Providers (TPPs) within the Open Banking ecosystem." />
            <MemberComponent route="/service5" name="Streamlining LG Issuance" position="Empowering bank guarantee applicants with a more convenient, efficient, and secure way to manage and issue bank guarantees through a fully digital experience." />
            <MemberComponent route="/service6" name="Streamlining LG Requests" position="Empowering bank guarantee beneficiaries with a streamlined and secure end-to-end process for managing and issuing bank guarantees by their vendors." />
            <MemberComponent route="/service7" name="Automating Customer Onboarding" position="Automates income verification, expense analysis, and affordability assessments, accelerating decision-making and processes." />
            <MemberComponent route="/service8" name="Automating Financial Verification" position="Automates income verification, expense analysis, and affordability assessments, accelerating decision-making and processes." />
            <MemberComponent route="/service9" name="Automating Bank-Account Verification" position="Automat sharing and verification of account details and ownership in near real time with organizations. eliminating the need for time-consuming and error-prone manual validation processes." />
            <MemberComponent route="/service10" name="Finance Decision Acceleration" position="Accelerating finance decisions for both banks and non-banking lenders by modernizing loan processes through the use of open banking and other data sources." />
            <MemberComponent route="/service11" name="Direct Payment" position="Facilitating efficient, instant, and transparent transactions directly between bank accounts, without intermediaries, utilizing features such as request to pay, scheduled payments, and recurring payments." />

          </TabContentList>
          <div style={{ textAlign: "-webkit-center" }}>
            <ListWrapperButtonNext onClick={(event) => handleClick("next", "list1245", "item1234")}><Icon>chevron_right</Icon></ListWrapperButtonNext>
          </div>
        </ListWrapper>

      </TabContentX2>








      <TabContentX2
        id="3"
        addToRef={addToREfs}
      // title="Tokyo"
      // subtext="Tokyo is the capital of Japan."
      >
        <ListWrapper>
          <TabContentList>
            <MemberComponent route="/service12" name="Financial System Insights" position="Enables financial institutions and regulators to track key financial system performance indicators in real time, improving decision-making processes and regulatory oversight." />
            <MemberComponent route="/service13" name="ATM Replenishment Management" position="A comprehensive solution addresses the complexities and challenges associated with ATM,CIT (Cash In Transit), Cash Centers/branches and replenishment management." />

          </TabContentList>
        </ListWrapper>

      </TabContentX2>


      {/* <SubText>
        <FormattedMessage
          id="app.usecases.subtitle"
          defaultMessage="The use cases that Leapro supports include but are not limited to:"
        />
      </SubText> */}

      {/* <Container>
        <Items>
          <Item></Item>
          <RoadMapItemX
            addToRef={addToREfs}
            title={busAccounting}
            subtext={busAccountingDetails}
          />
          <RoadMapItemX addToRef={addToREfs} title={pfm} subtext={pfmDetails} />
          <RoadMapItemX
            addToRef={addToREfs}
            title={accVer}
            subtext={accVerDetails}
          />
          <RoadMapItem
            addToRef={addToREfs}
            title={custId}
            subtext={custIdDetails}
          />
          <RoadMapItemX
            addToRef={addToREfs}
            title={prodComp}
            subtext={prodCompDetails}
          />
          <RoadMapItemX
            addToRef={addToREfs}
            title={credAss}
            subtext={credAssDetails}
          />
          {
            //   <RoadMapItemX
            //   addToRef={addToREfs}
            //   title=" SKY IS THE LIMIT...."
            //   subtext=""
            // />
          }
        </Items>
      </Container> */}
    </Section >
  );
};

export default Usecases;

import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { dark } from "../../styles/Themes";
import homeImg from "../../assets/build_together.png";
import homeImg1 from "../../assets/build_together1.png";

import { FormattedMessage } from "react-intl";
import { Context } from "../Wrapper";
import Button from "../Button";
import { useLocation } from "react-router-dom";
import { useLayoutEffect } from "react";

const Section = styled.section`
  /* min-height: 70vh; */
  /* min-height: 70vh; */

  width: 100%;
  /* background-color: ${(props) => props.theme.text}; */

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
flex-direction: column;
  overflow: hidden;

  direction: ${(props) => props.direction};
  flex-grow: 1;
  margin-top: ${(props) => props.theme.navHeight};
  margin-bottom:50px;


`;

const Container = styled.div`
  /* width: 79%; //section content wdith */
  margin: 2rem 90px;
  /* background-color: lightblue; */

  display: flex;
  justify-content: center;
  align-items: center;
  width: 1160px;

    margin-bottom:10px;

    align-items: end;
  @media (max-width: 75em) {
    width: 85%;
  }
  @media (max-width: 64em) {
    width: 90%;
  }

  @media (max-width: 48em) {
    align-items: center;

    width: 45vh;
    flex-direction: column;
    & > *:last-child {
      width: 45vh;
    }
  }
`;

const Box = styled.div`
  /* width: 50%; */
  height: 100%;
  /* min-height: 40vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom:10px;

  @media (max-width: 40em) {
    width: 40vh;
  }
`;

const Title = styled.h2`
  font-size: x-large;
  /* text-transform: capitalize; */

  color: ${(props) => props.theme.text};
  align-self: flex-start;
  /* display: flex;
  justify-content: center;
  align-self: flex-start; */

  align-items: center;
  margin: 3rem 0;
  /* border-bottom: 2px solid ${(props) => props.theme.text}; */
  width: 80%;
  /* text-align: left; */
  /* color: #002856; */

  @media (max-width: 48em) {
    font-size:x-large;
  }
`;

const SubText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.text};
  margin: 1rem 9;
  width: 80%;
  font-weight: 400;
  align-self: flex-start;

  @media (max-width: 64em) {
    width: 100%;
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontmd};
  }
`;
const Title1 = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  /* text-transform: capitalize; */
  color: ${(props) => props.theme.text};
  margin: 0 auto;
  width: 80%;
  font-weight: 500;

  align-self: flex-start;
  /* color: #002856; */

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxl};
    width: 100%;
  }

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
    text-align: center;
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;



const SubTextLight = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  color: rgba(${(props) => props.theme.textRgba}, 0.6);
  margin: 1rem auto;
  width: 80%;
  font-weight: 400;
  align-self: flex-start;

  @media (max-width: 64em) {
    width: 100%;
    font-size: ${(props) => props.theme.fontsm};
  }

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontsm};
    text-align: center;
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxs};
  }
`;

const ImgContainer = styled.div`
  /* width: 100%;

  video {
    width: 100%;
    height: auto;
  }

  @media (max-width: 64em) {
    min-width: 40vh;
  } */

  width: 30rem;
  margin: 0 1rem;
  margin-bottom: 1rem;

  /* border-radius: 20px; */

  @media (max-width: 75em) {
    width: 25rem;
  }

  @media (max-width: 64em) {
    width: 22rem;
  }

  @media (max-width: 48em) {
    width: 20rem;
  }
  @media (max-width: 38em) {
    width: 20rem;
  }

  img {
    width: 100%;
    height: auto;
  }
  display: flex;
`;


const Text = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  /* text-transform: capitalize; */
  color: rgba(${(props) => props.theme.textRgba}, 0.9);
  font-weight: 400;
  /* padding-bottom: 3rem; */
  /* margin-bottom: auto; */
  white-space: pre-line;
  flex-direction: column;
  line-height: 1.6;

  margin-bottom: 16px; //between paragraphs
  width: -webkit-fill-available;
  p {
    line-height: 1.6;
  }

  span{
    font-size: large;
    padding-right: 32px;
    padding-bottom: 12px;

  }

  @media (max-width: 64em) {
    flex-direction: column;
  }



`;


const Vision = styled.div`
margin: 2rem 2rem;
/* display: flex; */
/* flex-direction: column; */
/* justify-content: start; */
background-color: ${(props) => props.theme.lightGray};

padding: 16px;
border: 1px;
border-top-left-radius: 12px;
border-bottom-right-radius: 12px;
box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2);
width: 85%;

@media (max-width: 64em) {
  /* width: 90%; */
  width: 85%;


}

`;


const About = () => {
  const context = React.useContext(Context);

  const location = useLocation();
  // scroll to top of page after a page transition.
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
    // window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Section
      id="about"
      direction={context.locale.startsWith("ar") ? "rtl" : "ltr"}
    >

      <Container>

        <Box>
          <Title>About</Title>

          <Text>Leapro is a leading technology company dedicated to transforming the way financial and non-financial services are delivered.
          </Text>
          <Text>Our cutting-edge solutions, powered by open banking and advanced technology, empower businesses of all sizes, from banks and government agencies to enterprises and SMEs, to enhance their operations and create exceptional customer experiences.</Text>

          <Text><span>Key Solutions</span>
            <ul>
              <li>Bank Guarantees: Streamline and automate the letter of guarantee process, reducing errors and accelerating transactions.</li>
              <li>SME Financing: Bridge the gap between SMEs and lenders, facilitating access to affordable financing.</li>
              <li>Open Banking APIs: Provide seamless integration with the open banking ecosystem, enabling innovative financial products and services.</li>
              <li>Direct Payments: Avoid costly intermediaries and facilitate direct payments between bank accounts.</li>
              <li>Account and Financial Validation: Automate onboarding processes, reducing time and errors.</li>
              <li>And others..</li>
            </ul>

          </Text>

          {/* <Text><span>Why Choose Us?</span>
            <ul>
              <li>Advanced Technology: Our solutions are powered by cutting-edge technology to ensure efficiency and security.</li>
              <li>Customer-Centric Approach: We prioritize your needs and deliver tailored solutions to meet your specific requirements.</li>
              <li>Seamless Integration: Our solutions seamlessly integrate with your existing systems, minimizing disruption.</li>
              <li>Expert Support: Our team of experts is dedicated to providing exceptional support and guidance.</li>
            </ul>
          </Text> */}



        </Box>
        <Box>
          <Vision>
            <Text><span>Our Vision</span>
              <p>
                Transform how financial and government services and products are delivered to users.
              </p>
            </Text>
            <Text><span>Our Mission</span>
              <p>
                Build innovative solutions that are add value to customers, secure and reliable.            </p>
            </Text>
            <Text><span> Our Core Values:</span>
              <p>Our team operates by core values that shape our actions and decisions, ensuring we hire and evaluate team members based on these principles:
                <ul>
                  <li>Drive Change</li>
                  <li>Exceed Expectations</li>
                  <li> Be Relentlessly Disciplined</li>
                </ul>
              </p>
            </Text>
          </Vision>
        </Box>

      </Container>
      <Box>      <Text>Contact us to learn more about how our innovative solutions can benefit your business.</Text>
      </Box>

      <Button text="Contact Us"></Button>

    </Section>
  );
};

export default About;

import { ThemeProvider } from "styled-components";
import GlobalStyle from "./styles/GlobalStyles";
import { light } from "./styles/Themes";
import Navigation from "./components/Navigation";
import Home from "./components/sections/Home";

import About from "./components/sections/About.js";
import Footer from "./components/Footer";
import { Fragment } from "react";
import ScrollToTop from "./components/ScrollToTop";
import GenericNotFound from "./components/GenericNotFound";

import Usecases from "./components/sections/Usecases";
import Features from "./components/sections/Features";
import Mission from "./components/sections/Mission";

import UsecasesExtenstion from "./components/sections/UsecasesExtenstion.";
import Recognition from "./components/sections/Recognition";
import ForFI from "./ForFI";

import { Routes, Route } from "react-router-dom";
import GetInTouch from "./GetInTouch";
import Start from "./Start";
import ForBusiness from "./ForBusiness";
import Pfm from "./Pfm";
import { Context } from "./components/Wrapper";
import "./fonts/IBMPlexSansArabic-Medium.ttf";
import Service1 from "./Service1.js";
import Product1 from "./Product1.js";
import GenericUnderConstruction from "./components/GenericUnderConstruction.js";
import LeaproExistingUser from "./LeaproExistingUser.js";

function App(props) {
  return (
    <Fragment>
      <GlobalStyle />
      <ThemeProvider theme={light}>
        <Navigation direction={props.lang === "en" ? "ltr" : "rtl"} />

        <main>
          <Routes>
            <Route path="/" element={<Start />} />
            <Route path="/pfm/:lang" element={<Pfm />} />
            <Route path="/pfm/" element={<Pfm />} />

            <Route path="/for-fi" element={<ForFI />} />
            <Route path="/for-business" element={<ForBusiness />} />

            <Route path="/blog" element={<GenericUnderConstruction />} />

            <Route path="/service1" element={<Service1 />} />
            <Route path="/service2" element={<GenericUnderConstruction />} />
            <Route path="/service3" element={<GenericUnderConstruction />} />
            <Route path="/service4" element={<GenericUnderConstruction />} />
            <Route path="/service5" element={<GenericUnderConstruction />} />
            <Route path="/service6" element={<GenericUnderConstruction />} />
            <Route path="/service7" element={<GenericUnderConstruction />} />
            <Route path="/service8" element={<GenericUnderConstruction />} />
            <Route path="/service9" element={<GenericUnderConstruction />} />
            <Route path="/service10" element={<GenericUnderConstruction />} />
            <Route path="/service11" element={<GenericUnderConstruction />} />
            <Route path="/service12" element={<GenericUnderConstruction />} />
            <Route path="/service13" element={<GenericUnderConstruction />} />


            <Route path="/product1" element={<Product1 />} />
            <Route path="/product2" element={<GenericUnderConstruction />} />
            <Route path="/product3" element={<GenericUnderConstruction />} />

            <Route path="/about" element={<About />} />
            <Route path="/contact-us" element={<GetInTouch />} />


            <Route path="/lg_request/*" element={<LeaproExistingUser />} />
            <Route path="*" element={<GenericNotFound />} />
          </Routes>
        </main>
        <Footer />
        <ScrollToTop />
      </ThemeProvider>
    </Fragment>
  );
}

export default App;

import styled, { ThemeProvider } from "styled-components";
import GlobalStyle from "./styles/GlobalStyles";
import { light } from "./styles/Themes";
import Navigation from "./components/Navigation";
import Home from "./components/sections/Home";

import About from "./components/sections/About.js";
import Footer from "./components/Footer";
import { Fragment } from "react";
import ScrollToTop from "./components/ScrollToTop";
import Usecases from "./components/sections/Usecases";
import Features from "./components/sections/Features";
import Mission from "./components/sections/Mission";

import UsecasesExtenstion from "./components/sections/UsecasesExtenstion.";
import Recognition from "./components/sections/Recognition";

import Contact from "./components/sections/Contact";

const Section = styled.section`
  min-height: calc(
    100vh -
      (
        ${(props) => props.theme.navHeight} +
          ${(props) => props.theme.footerHeight}
      )
  );

  width: 100vw;
  position: relative;
  background-color: ${(props) => props.theme.body}; ;
`;

function GetInTouch() {
  return (
    <Fragment>
      <Section>
        <Contact />
      </Section>
    </Fragment>
  );
}

export default GetInTouch;

//This file contains variables for different Themes

export const light = {
  body: "#fff",
  // text: "#202020", //black shade
  text: "#002856", //black shade

  bodyRgba: "255,255, 255",

  textRgba: "32,32,32",

  carouselColor: "#EEEDDE",

  fontxs: "12px",
  fontsm: "14px",
  fontmd: "l6px", //1em = 16px
  fontmd2: "l8px", //1em = 16px
  fontlg: "20px",
  fontxl: "32em",
  fontxxl: "48em",
  fontxxxl: "64px",

  fontButton: "0.875em",

  navHeight: "88px",
  width: "1160px",
  footerHeight: "278px",

  //colors
  lightGray: "#f2f5fa",
  lightGrayText: "#ABB8C3",

  blueMed: "#3065D0",
  brandBlue: "#0693E3",
};

export const dark = {
  // body: "#202020",
  body: "#303035",

  text: "#fff", //light shade
  bodyRgba: "32,32,32",
  textRgba: "255,255, 255",

  carouselColor: "#EEEDDE",

  fontxs: "12px",
  fontsm: "14px",
  fontmd: "l6px", //1em = 16px
  fontmd2: "l8px", //1em = 16px
  fontlg: "20px",
  fontxl: "32em",
  fontxxl: "48em",
  fontxxxl: "64px",

  fontButton: "0.875em",

  navHeight: "100px",
  footerHeight: "278px",
};

import React from "react";
import styled from "styled-components";

import img1 from "../../assets/greate_ux.png";
import img2 from "../../assets/secure.png";
import img3 from "../../assets/simple.png";
import img4 from "../../assets/value.png";
import { FormattedMessage } from "react-intl";
import { Context } from "../Wrapper";
import { useIntl } from "react-intl";

const Section = styled.section`
  /* min-height: 100vh; */
  /* min-height: 110vh; */
  width: 100vw;

    /* background-color: ${(props) => props.theme.body}; */
  position: relative;

  direction: ${(props) => props.direction};
  display: flex;
  flex-direction: column;
  align-items: center;

`;

const Title = styled.h1`
  /* font-size: ${(props) => props.theme.fontxxl}; */
  text-transform: capitalize;
  font-size: xx-large;
  /* color: ${(props) => props.theme.text}; */
  /* align-self: flex-start; */
  /* display: flex; */
  /* justify-content: right; */
  /* align-items: left; */
  /* margin: 1rem auto; */
  /* border-bottom: 2px solid ${(props) => props.theme.text}; */
  /* width: fit-content; */
  /* width: 80%; */
  /* color: #002856; */
  width: ${(props) => props.theme.width};
  padding-top: 90px;




  @media (max-width: ${(props) => props.theme.width}) {
    font-size: x-large;
    width: 90%;

}
`;

const SubText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.text};
  /* margin: 1rem auto; */
  font-weight: 400;
  /* align-items: center; */
  /* align-self: center; */
  /* align-self: flex-start; */
  /* text-align: center; */



  @media (max-width: ${(props) => props.theme.width}) {
    width: 90%;
    font-size: ${(props) => props.theme.fontmd};
  }
`;

const Container = styled.div`
  width: 85%;
  margin: 2rem auto;
  width: 1160px;

  display: flex;
  /* justify-content: space-between; */
  justify-content: space-between;
  /* align-content: center; */
  flex-wrap: wrap;
  gap: 24px;
  @media (max-width: 64em) {
    width: 85%;
  }
  @media (max-width: 48em) {
    width: 90%;
    justify-content: center;
    align-items: center;
  }
  margin-bottom: 5em;
`;

const Item = styled.div`
  width: calc(20rem - 4vw);
  /* padding: 24px 24px; */
  padding-top: 24px;
  color: ${(props) => props.theme.body};
  /* margin: 1rem 2rem; */
  position: relative;

  /* border: 2px solid ${(props) => props.theme.text}; */
  border-radius: 20px;
  transition: all 0.3s ease;
  flex-grow: 1;

  &:hover {
    img {
      transform: translateY(-2rem) scale(1.2);
    }
  }

  @media (max-width: 30em) {
    width: 70vw;
  }
`;

const ImageContainer = styled.div`
  width: 60%;
  margin: 0 auto;
  /* background-color: ${(props) => props.theme.carouselColor}; */
  background-color: #f5f5f5;

  /* border: 1px solid ${(props) => props.theme.text}; */
  padding: 1rem;

  border-radius: 20px;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
    transition: all 0.3s ease;
  }
`;

const Name = styled.h3`
  /* font-size: ${(props) => props.theme.fontsm}; */
  display: flex;
  /* align-items: center; */
  /* justify-content: left; */
  /* text-transform: uppercase; */
  color: ${(props) => props.theme.text};
  margin-top: 1rem;
  /* text-align: -webkit-center; */
  min-height: 40px;
`;

const Position = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: rgba(${(props) => props.theme.textRgba}, 0.9);
  font-weight: 400;
  padding-top: 1rem;
  line-height: normal;
  line-height: 1.6;

`;

const MemberComponent = ({ img, name = " ", position = " " }) => {
  return (
    <Item>
      {/* <ImageContainer>
        <img src={img} alt={name} />
      </ImageContainer> */}
      <Name>{name}</Name>
      <Position>{position}</Position>
    </Item>
  );
};

const Features = () => {
  const context = React.useContext(Context);

  const intl = useIntl();
  const title1 = intl.formatMessage({ id: "app.features.greateFinEx" });
  const details1 = intl.formatMessage({
    id: "app.features.greateFinExDetails",
  });

  const title2 = intl.formatMessage({ id: "app.features.extreamSec" });
  const details2 = intl.formatMessage({
    id: "app.features.extreamSecDetails",
  });

  const title3 = intl.formatMessage({ id: "app.features.extreamSimple" });
  const details3 = intl.formatMessage({
    id: "app.features.extreamSimpleDetails",
  });

  const title4 = intl.formatMessage({ id: "app.features.value" });
  const details4 = intl.formatMessage({
    id: "app.features.valueDetails",
  });

  return (
    <Section
      id="team"
      direction={context.locale.startsWith("ar") ? "rtl" : "ltr"}
    >
      <Title>
        {/* <FormattedMessage
          id="app.features.title"
          defaultMessage="Leapro Delivers"
        /> */}
        Why choose Leapro?
      </Title>
      {/* <SubText>
        <FormattedMessage
          id="app.features.subtitle"
          defaultMessage="The use cases that Leapro supports include but are not limited to:"
        />
      </SubText> */}
      <Container>


        {/* <MemberComponent img={img2} name={title1} position={details1} />
        <MemberComponent img={img4} name={title2} position={details2} />
        <MemberComponent img={img1} name={title3} position={details3} /> */}
        <MemberComponent img={img2} name="Extensive Experience" position="Extensive hands-on experience in the technology and banking sectors, with a strong track record of designing and implementing mission-critical systems that consistently surpass expectations." />
        <MemberComponent img={img4} name="Advanced Technology" position="Leveraging cutting-edge technology to provide tailored services and solutions that seamlessly integrate with your existing systems, minimizing disruption.." />
        <MemberComponent img={img1} name="Client-Focused Innovation" position="Needs and challenges are the source of Innovations, we closely collaborate with our partners to deliver innovations that aligns with their business goals." />      </Container>
    </Section>
  );
};

export default Features;

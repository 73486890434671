import styled, { ThemeProvider } from "styled-components";
import GlobalStyle from "./styles/GlobalStyles";
import { light } from "./styles/Themes";
import Navigation from "./components/Navigation";
import Home from "./components/sections/Home";

import About from "./components/sections/About.js";
import Footer from "./components/Footer";
import { Fragment } from "react";
import ScrollToTop from "./components/ScrollToTop";
import Usecases from "./components/sections/Usecases";
import Features from "./components/sections/Features";
import Mission from "./components/sections/Mission";

import UsecasesExtenstion from "./components/sections/UsecasesExtenstion.";
import Recognition from "./components/sections/Recognition";
import ForFIInfo from "./components/sections/ForFIInfo";
import Service1TypeWriterText from "./components/service_sections/Service1TypeWriterText.js";
import Service1Home from "./components/service_sections/Service1Home.js";
import Service1Pitch from "./components/service_sections/Service1Pitch.js";
import Service1Features from "./components/service_sections/Service1Features.js";
import Service1Statement from "./components/service_sections/Service1Statement.js";
import SeeIt from "./components/sections/SeeIt.js";

const Section = styled.section`
  /* min-height: calc(
    100vh -
      (
        ${(props) => props.theme.navHeight} +
          ${(props) => props.theme.footerHeight}
      )
  ); */
  /* width: 100vw; */
  position: relative;
  /* height: 400px; */
  background-color: ${(props) => props.theme.body}; 
`;

function Service1() {
  return (
    <Section>
      <Service1Home />
      <Service1Pitch />
      <Service1Features />
      <Service1Statement />
      <SeeIt />

    </Section>
  );
}

export default Service1;
